input,textarea {
  width:100%;
  display:block 
}

@font-face {
  font-family: "Outfit";
  src: url("./fonts/Outfit-VariableFont_wght.ttf") format("truetype");
}

*,
::after,
::before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  font-family: "Outfit", sans-serif;
  font-size: var(--font-size-xs);
  line-height: 1.8;
  font-weight: 400;
  background: #ffffff;
  color: #43425d;
  overflow-x: hidden !important;
}

html {
  font-family: "Outfit", sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}

.h1,
.h2,
.h3,
.h4,
.h5,
h1,
h2,
h3,
h4,
h5 {
  line-height: 1.5;
  font-weight: 400;
  color: #43425d;
}

a {
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
  color: #43425d;
  text-decoration: none;
}

a:focus,
a:hover {
  text-decoration: none !important;
  outline: 0 !important;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-bottom: 2px solid #f09a5b;
}

.active-page {
  border-bottom: 2px solid #f09a5b;
}

.disabled-text {
  color: rgb(192, 192, 192);
  pointer-events: none;
}

.button-one-mini {
  min-width: 125px !important;
}

.button-one {
  font-size: 12px;
  text-transform: uppercase;
  color: #ffffff;
  font-family: "Outfit";
  line-height: 20px;
  text-align: center;
  /* width: 138px; */
  min-width: 156px;
  /* height: 29px; */
  border-radius: 15px;
  background-color: var(--keppel);
  transition: background-color 0.3s ease;
}

.button-one:hover {
  color: var(--mosque) !important;
  transition: background-color 0.3s ease !important;
}

.button-one.active {
  background-color: white !important;
  border: 3px solid var(--mosque);
  color: var(--mosque);
}

.button-login-mini {
  min-width: 125px !important;
}

.button-login {
  font-size: 12px;
  text-transform: uppercase;
  color: #ffffff;
  font-family: "Outfit";
  line-height: 20px;
  text-align: center;
  /* width: 138px; */
  min-width: 156px;
  /* height: 29px; */
  border-radius: 15px;
  background-color: var(--keppel);
  transition: background-color 0.3s ease;
}

.button-login:hover {
  color: var(--white) !important;
  transition: background-color 0.3s ease !important;
  background-color: var(--mosque);
}

.button-dark {
  text-transform: uppercase;
  color: #ffffff;
  font-family: "Outfit";
  font-size: 15px;
  line-height: 10px;
  text-align: center;
  /* width: 166px; */
  height: 36px;
  border-radius: 20px !important;
  border-radius: 15px;
  background-color: var(--blue-whale);
  transition: background-color 0.3s ease;
}

.button-dark.clicked {
  background-color: white !important;
  border: 3px solid var(--blue-whale);
  color: var(--blue-whale);
}

.button-dark.clicked:hover {
  color: var(--blue-whale);
}

.button-dark:hover {
  background-color: #026773;
  color: #ffffff;
}

.button-light {
  font-size: 15px;
  text-transform: uppercase;
  color: #ffffff;
  font-family: "Outfit";
  font-size: 15px;
  line-height: 12px;
  text-align: center;
  /* width: 175px; */
  height: 36px;
  border-radius: 15px;
  background-color: var(--keppel);
  transition: background-color 0.3s ease;
}

.button-light:hover {
  color: #ffffff;
  background-color: var(--mosque);
  transition: background-color 0.3s ease;
}

.button-light.clicked {
  background-color: white !important;
  border: 3px solid var(--blue-whale);
  color: var(--blue-whale);
}

.button-light.clicked:hover {
  color: var(--blue-whale);
}

.button-ol-white {
  text-transform: uppercase;
  font-family: "Outfit";
  font-size: 15px;
  line-height: 10px;
  text-align: center;
  min-width: 170px;
  height: 36px;
  border-radius: 20px !important;
  border-radius: 15px;
  background-color: var(--blue-whale);
  transition: background-color 0.3s ease;
  background: #ffffff !important;
  border: 5px solid var(--mosque);
  color: #026773 !important;
}

.button-ol-white.active {
  border: 3px solid var(--mosque);
  height: 36px;
  line-height: 10px;
  background: var(--mosque) !important;
  color: #ffffff !important;
}

.button-ol-white:hover {
  background: #026773 !important;
  color: #ffffff !important;
}

.button-ol-dark {
  text-transform: uppercase;
  font-family: "Outfit";
  font-size: 15px;
  line-height: 10px;
  text-align: center;
  min-width: 170px;
  height: 36px;
  border-radius: 20px !important;
  border-radius: 15px;
  background-color: var(--blue-whale);
  transition: background-color 0.3s ease;
  background: #ffffff !important;
  border: 5px solid #026773;
  color: #026773 !important;
}

.button-ol-dark.active {
  background-color: var(--mosque) !important;
  border: 3px solid var(--mosque);
  color: var(--white) !important;
}

.button-ol-dark:hover {
  background: var(--mosque) !important;
  color: #ffffff !important;
}

.button-white {
  font-size: var(--font-size-xxxxs);
  color: var(--black);
  font-family: "Outfit";
  font-weight: 800;
  line-height: 10px;
  text-align: center;
  height: 28px;
  border-radius: 15px;
  background-color: var(--white);
  transition: background-color 0.3s ease;
}

.button-white:hover {
  color: #ffffff;
  background-color: var(--mosque);
  transition: background-color 0.3s ease;
}

.button-white.clicked {
  background-color: white !important;
  border: 3px solid var(--blue-whale);
  color: var(--blue-whale);
}

.button-white.clicked:hover {
  color: var(--blue-whale);
}

/* SideBar */

#sidebar {
  min-width: 210px;
  max-width: 210px;
  background-image: linear-gradient(180deg,
      #026773 0%,
      #42999f 51%,
      #93d8d8 100%);
  color: #fff;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

#sidebar.active {
  min-width: 60px;
  max-width: 60px;
  text-align: center;
}

#sidebar .active-link {
  background-color: var(--keppel) !important;
}

#sidebar.active ul.components li {
  font-size: 14px;
}

#sidebar.active ul.components li a {
  padding: 10px 0;
}

#sidebar.active ul.components li a span {
  margin-right: 0;
  display: block;
}

#sidebar.active ul.components li a .colp {
  display: none;
}

#sidebar.active .footer {
  display: none;
}

#sidebar .logo {
  display: block;
  color: #fff;
  font-weight: 900;
  padding: 10px 30px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

@media (prefers-reduced-motion: reduce) {
  #sidebar .logo {
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
  }
}

#sidebar ul.components {
  padding: 0;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

@media (prefers-reduced-motion: reduce) {
  #sidebar ul.components {
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
  }
}

#sidebar ul li {
  font-size: 12px;
}

#sidebar ul li>ul {
  margin-left: 10px;
}

#sidebar ul li>ul li {
  font-size: 14px;
}

#sidebar ul li a {
  padding: 10px 30px;
  display: block;
  color: #fff;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

#sidebar ul li a span {
  margin-right: 15px;
}

@media (max-width: 991.98px) {
  #sidebar ul li a span {
    display: block;
  }
}

#sidebar ul li a:hover {
  color: #fff;
}

#sidebar ul li.active>a {
  background: 0 0;
  color: #fff;
}

@media (max-width: 991.98px) {
  #sidebar {
    min-width: 70px;
    max-width: 70px;
    text-align: center;
    margin-left: -80px !important;
  }

  #sidebar.active {
    margin-left: 0 !important;
  }
}

a[data-toggle="collapse"] {
  position: relative;
}

@media (max-width: 991.98px) {
  #sidebarCollapse span {
    display: none;
  }
}

/* End Sidebar */

.content {
  width: 100%;
  padding: 0;
  min-height: 100vh;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.left {
  font-size: 30px;
  line-height: 31px;
  color: var(--blue-whale);
  font-family: "Outfit";
}

.top-nav {
  background-color: #012e40 !important;
  z-index: 99;
  margin: 0;
}

.nav-item {
  padding: 0 !important;
  margin: 0 !important;
}

.nav-link {
  padding: 2px 0 0 0 !important;
  margin: 0 10px;
}

.top-nav img {
  width: 120px;
}

.top-nav ul li a {
  color: white !important;
}

.top-nav ul li button {
  color: white !important;
}

.top-nav ul li {
  margin: 7px 0 !important;
}

.fa-bell {
  font-size: 20px;
  color: black;
  margin-top: 2px;
}

.B {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background-color: #026773;
  margin-left: 14px !important;
}

.B p {
  font-family: "Roboto", sans-serif;
  font-size: 17px;
  color: #ffffff;
  font-weight: 700;
  margin: 0;
}

.rounded-circle {
  width: 7px !important;
  height: 7px !important;
  background-color: #f8a90f !important;
  margin-left: -3px;
}

.heading {
  font-weight: 800;
  font-size: 60px;
  line-height: 50px;
  color: var(--blue-whale);
  font-family: "Outfit" !important;
}

.portfolio-container {
  max-height: 350px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 10px 0;
}

.portfolio-container .row {
  justify-content: center;
  align-items: center;
}

.portfolio-wrapper {
  /* background-image: linear-gradient(233deg, #3ca6a6 0%, #012e40 100%); */
  margin: 5px;
  height: 155px;
  max-width: 240.68px;
}

.gofolio {
  background-image: linear-gradient(233deg, #3ca6a6 0%, #012e40 100%);
}

.userDefined {
  background-color: var(--blue-whale);
}

.top-performer-loading-placeholder {
  height: 190px;
  min-width: 190px;
}

.top-performer-wrapper {
  margin: 5px;
  height: 190px;
  max-width: 275px;
}

.top-bar {
  height: 50px;
  background-color: var(--blue-whale);
}

.top-bar ul {
  display: flex;
  list-style-type: none;
  padding: 7px;
}

.top-bar ul li a {
  margin: auto 10px;
  color: #ffffff;
  font-size: var(--font-size-xxs);
}

.top-bar ul li .active {
  border-bottom: 2px solid #f09a5b;
}

.portfolio-text-name {
  font-size: var(--font-size-xxxs);
  font-weight: lighter;
  line-height: var(--font-size-xxxs);
  color: #ffffff;
  margin-bottom: 0;
}
.portfolio-text-type {
  font-size: var(--font-size-xxxs);
  font-weight: normal;
  line-height: var(--font-size-xxxs);
  color: #ffffff;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

.portfolio-top-performer-text {
  font-size: var(--font-size-xxxs);
  font-weight: lighter;
  line-height: var(--font-size-xxxs);
  color: #ffffff;
  margin-bottom: 0;
}

.portfolio-top-performer-text-type {
  font-size: var(--font-size-xxxs);
  font-weight: normal;
  line-height: var(--font-size-xxxs);
  color: #ffffff;
  margin-top: 0;
  margin-bottom: 0;
}

.portfolio-box img {
  margin-top: -20px;
  margin-right: -3px;
}

.portfolio-box .percentage {
  font-size: 40px;
  color: #ffffff;
  line-height: 50px;
  margin-bottom: 0;
}

.portfolio-box .d-flex {
  justify-content: space-between;
}

.portfolio-box .buttonDetails {
  width: 82px;
  height: 19px;
  border-radius: 10px;
  background-color: #ffffff;
  font-size: 11px;
  line-height: 7px;
  color: var(--blue-whale);
  border: none;
  text-align: center;
}

.portfolio-box .buttonX {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: #ffffff;
  line-height: 7px;
  color: var(--blue-whale);
  border: none;
  text-align: center;
}

.portfolio-box .buttonX i {
  font-size: 9px;
  padding-bottom: 2px;
}

.top-performer-box img {
  margin-top: -7px;
  margin-right: -4px;
}

.top-performer-box .percentage {
  font-size: 70px;
  color: #ffffff;
  line-height: 60px;
}

.top-performer-box .d-flex {
  justify-content: space-between;
}

.top-performer-box .buttonDetails {
  width: 100px;
  height: 25px;
  border-radius: 10px;
  background-color: #ffffff;
  font-size: var(--font-size-xxxs);
  line-height: var(--font-size-xxxs);
  color: var(--blue-whale);
  border: none;
  text-align: center;
}

.security-box img {
  margin-top: -20px;
  margin-right: -3px;
}

.security-text {
  font-size: 40px;
  font-weight: 600;
  color: #ffffff;
  line-height: 50px;
}

.security-box .buttonX {
  /* width: 18px; */
  height: 18px;
  border-radius: 50%;
  background-color: #ffffff;
  line-height: 7px;
  color: var(--blue-whale);
  border: none;
  text-align: center;
}

.security-box .buttonX i {
  font-size: 9px;
}

.box-container .box {
  border: 3px solid #026773;
}

.content-wrapper {
  margin: 0 20px;
}

.box-top {
  font-size: var(--font-size-m);
  line-height: 20px;
  color: #ffffff;
  font-family: "Outfit";
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 58px;
  /* background-image: linear-gradient(
    78deg,
    #026773 0%,
    #56a8ad 64%,
    #93d8d8 100%
  ); */
}

.box-inner-text {
  text-align: center;
}

.box-inner-text p {
  font-size: var(--font-size-s);
  margin-top: 15px;
  line-height: 35px;
  color: var(--blue-whale);
  font-family: "Outfit";
  text-align: center;
  font-weight: 600;
}

/* .box-inner-text .btn {
  width: 200px;
  height: 36px;
  border-radius: 20px;
  background-image: linear-gradient(258deg, #3ca6a6 0%, #3ca6a6 100%);
  font-size: 16px;
  color: #ffffff;
  font-family: "Outfit";
  text-align: center;
} */

/* .box-inner-text .btn-2 {
  background: #ffffff !important;
  border: 5px solid #026773;
  color: #026773;
  line-height: 14px;
  margin: 12px 0;
}

.box-inner-text .btn-3 {
  width: 97px;
  height: 28px;
  border-radius: 15px;
  background-color: var(--keppel);
  font-size: 11px;
  color: #ffffff;
  font-family: "Outfit";
  text-align: center;
}

.box-inner-text .btn-4 {
  background: #ffffff !important;
  border: 5px solid #026773;
  color: #026773;
  line-height: 8px;
} */

.box-inner-text .d-flex {
  justify-content: center;
}

.box-return .left {
  font-size: var(--font-size-xxs);
  line-height: 32px;
  color: #ffffff;
  font-family: "Outfit";
  text-align: center;
  /* width: 133px; */
  min-width: 210px;
  word-wrap: break-word;

  height: 31px;
  border-radius: 15px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  background-color: var(--keppel);
}

.box-return .right {
  font-size: var(--font-size-xxs);
  line-height: 27px;
  color: #026773;
  font-family: "Outfit";
  text-align: center;
  /* width: 133px; */
  min-width: 130px;
  height: 31px;
  border-radius: 15px;
  border: 3px solid #026773;
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.portfolio-date {
  font-size: var(--font-size-xs);
  line-height: 35px;
  color: var(--blue-whale);
  font-family: "Outfit";
  text-align: center;
  height: 32px;
  background-color: #cee2e4;
}

/* .box button {
  font-size: 12px;
  line-height: 20px;
  color: #ffffff;
  font-family: "Outfit";
  text-align: center;
  width: 270px;
  height: 32px;
  border-radius: 16px;
  background-color: var(--blue-whale);
}

.box button:hover {
  background-color: #026773;
  color: #ffffff;
} */

.btn-5 {
  font-size: 12px;
  line-height: 20px;
  color: #ffffff;
  font-family: "Outfit";
  text-align: center;
  width: 270px;
  height: 32px;
  border-radius: 16px;
  background-color: var(--blue-whale);
}

.btn-5:hover {
  background-color: #026773;
  color: #ffffff;
}

.last-calc-box {
  max-width: 620px;
  border: 1px solid #026773;
}


.chart-wrapper {
  position: relative;
  margin: 25px;

  font-size: 12px;
  color: #ffffff !important;
  font-family: "Outfit";
  text-align: center;
  filter: drop-shadow(0px 3px 3.5px #012e40);
}

/* My Account */
.account-container {
  margin: 0 20px 40px 20px;
}

.account-topbar {
  height: 280px;
  position: relative;
}

.account-container .img-box {
  height: 230px;
  border-radius: 5px;
  background-image: linear-gradient(180deg,
      #93d8d8 0%,
      #4299a0 39%,
      #026773 100%);
}

.account-container .details-box {
  width: 98%;
  height: 100px;
  border-radius: 5px;
  filter: drop-shadow(0px 10px 7px rgba(187, 187, 187, 0.36));
  background-color: #ffffff;
  position: absolute;
  right: 0;
  bottom: 0;
}

.img-box-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 260px;
  position: relative;
  border-radius: 5px;
}

.img-box-inner img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  object-position: center;
}

.img-box-inner .upload-icon {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.profile-img {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 99px;
  height: 99px;
  border-radius: 49px;
  background-color: #026773;
  margin: -15px 10px 0 20px;
}

.profile-img h1 {
  font-family: "Roboto", sans-serif;
  font-size: 75px;
  color: #ffffff;
  font-weight: 700;
  margin: 0;
}

.account-topbar .details-box .details-box-inner {
  display: flex;
}

.account-topbar .details-box h2 {
  font-size: 17px;
  color: #1e1e1e;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
  padding: 35px 10px;
}

.account-topbar .details-box .profile-info {
  font-size: 12px;
  color: #656565;
  font-family: "Outfit";
  margin-left: auto;
  padding: 20px 100px 20px 0;
  line-height: 30px;
}

.account-topbar .details-box .profile-info p {
  margin: 0 10px;
}

.account-topbar .details-box .profile-info .icon {
  margin: 0 8px;
}

.email-box-wrapper .d-flex {
  justify-content: space-between;
}

.email-box-wrapper .btn {
  width: 166px;
  height: 36px;
  border-radius: 20px;
  font-size: 15px;
  color: #ffffff;
  font-family: "Outfit";
  text-align: center;
  cursor: pointer !important;
}

.email-box-wrapper .d-flex p {
  font-size: 18px;
  color: var(--blue-whale);
  font-family: "Outfit";
}

.form-control {
  border-radius: 0;
}

.label {
  color: #43425d;
}

.bordertag {
  background-color: #000000 !important;
  border: 3px solid #012e40 !important;
  margin: 30px 0;
}

/* Pricing */
.pricing-container {
  margin: 10px 120px;
}

.pricing-inner-box {
  filter: drop-shadow(0px 2px 3.5px rgba(0, 0, 0, 0.04));
  background-color: var(--blue-whale);
  padding: 40px 0;
}

.pricing-inner-box h2 {
  font-size: 17px;
  color: #ffffff;
  font-family: "Outfit";
  text-align: center;
}

.pricing-inner-box h1 {
  font-size: 75px;
  color: #ffffff;
  font-family: "Outfit";
  text-align: center;
}

.pricing-inner-box p {
  font-size: 14px;
  color: #ffffff;
  font-family: "Outfit";
  text-align: center;
}

.pricing-inner-box button {
  line-height: 12px;
  height: 34px;
  padding: 10px 15px;
  border-radius: 16px;
  background-color: #ffffff;
}

.pricing-faq {
  height: 50px;
  background-color: var(--blue-whale);
}

.accordion-body {
  font-size: 15px;
  line-height: 20px;
  color: var(--blue-whale);
  font-family: "Outfit";
}

.contact-box p {
  font-size: 11px;
  line-height: 15px;
  color: var(--blue-whale);
  font-family: "Outfit";
}

.contact-box input {
  border-radius: 15px;
  border: 2px solid var(--blue-whale);
  font-size: 11px;
  line-height: 15px;
  color: var(--blue-whale);
  font-family: "Outfit";
}

.contact-box textarea {
  border-radius: 15px;
  border: 2px solid var(--blue-whale);
  font-size: 11px;
  line-height: 15px;
  color: var(--blue-whale);
  font-family: "Outfit";
}

.contact-box button {
  width: 84px;
  height: 32px;
  line-height: 10px;
  border-radius: 17px;
  background-color: var(--blue-whale);
  color: #ffffff;
  text-transform: uppercase;
}

.contact-box button:hover {
  background-color: #026773;
  color: white;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  opacity: 1;
  pointer-events: auto;
}

.btn-group .dropdown-toggle::after {
  display: none;
}

.sort-by p {
  color: white !important;
  margin-right: 7px;
}

.sort-by .form-select {
  font-size: var(--font-size-xxxs);
  border-radius: 0;
  margin-top: -1px;
}

.offcanvas {
  background-image: linear-gradient(180deg,
      #026773 0%,
      #42999f 51%,
      #93d8d8 100%);
  color: #fff;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.offcanvas ul li a {
  color: white;
}

.offcanvas ul li {
  color: white;
  padding: 10px;
}

.offcanvas ul li a img {
  margin-right: 10px;
}

.offcanvas .active-link {
  background-color: var(--keppel) !important;
}

.offcanvas .btn-close {
  color: white !important;
}

p {
  line-height: 18px;
  color: #9191a6;
}

.heading {
    font-size: 30px;
    line-height: 31px;
    color: #012e40;
    font-family: "Outfit" !important;
  }

  .square {
    width: 13px;
    height: 13px;
    background-color: #ffffff;
    margin-top: 2.1px;
  }

/* -------------------- Blog Page ------------------- */

/* .blog-hero .image{
width: 100%;
height: 500px;
background-color: #012e40;
} */
.blog-hero .image img {
  object-fit: cover;
  width: 100%;
  max-height: 400px;
  margin-top: 10px;
}

.blog-light-box {
  border-radius: 3px;
  background-color: #f2f9ff;
  border-left: 5px solid #4b2bb0;
}

.blog-light-box p {
  color: #3ca6a6;
  font-weight: 400;
  font-style: italic;
  font-family: "Open Sans";
  padding: 15px 20px;
}

.blog-left-section h6 {
  color: #3ca6a6 !important;
  font-weight: 800;
  font-family: sans-serif;
  line-height: 30px;
}

.blog-left-section ul {
  margin-left: -17px !important;
  margin-top: 15px;
}

.blog-left-section ul li {
  margin: 10px 0;
}

.blog-left-section button {
  width: 65px;
  height: 25px;
  border-radius: 15px;
  background-color: #ffffff;
  border: 1px solid #ebebeb;
  font-size: 7px;
  letter-spacing: 0px;
  line-height: 16px;
  color: #9191a6;
  font-weight: 700;
  font-family: sans-serif;
  margin: 0 5px;
}


.blog-left-section .tagbutton:focus {
  background-image: linear-gradient(-60deg, #026773 0%, #93d8d8 100%);
  color: whitesmoke;
}

.blog-left-section .tagbutton:hover {
  background-image: linear-gradient(-60deg, #026773 0%, #93d8d8 100%);
  color: whitesmoke;
}


.blog-left-section .d-flex {
  justify-content: space-between;
}

.blog-left-section .d-flex i {
  margin-top: 7px;
  padding: 0 3px;
  cursor: pointer;
}

.blog-left-section .card-img-top {
  height: 150px;
  object-fit: cover;
}

.blog-left-section .card .card-title {
  font-size: 9px;
  color: #012e40;
  font-family: "Outfit";
}

.blog-left-section .card a {
  font-size: 10px;
  color: #3ca6a6;
  font-weight: 700;
  font-family: sans-serif;
  text-decoration: none;
}

.blog-left-section .card a:hover {
  border: none;
}

.blog-right-section button {
  /* width: 65px; */
  height: 25px;
  padding: 0 12px;
  margin: 5px;
  border-radius: 15px;
  background-color: #ffffff;
  border: 1px solid #ebebeb;
  font-size: 7px;
  letter-spacing: 0px;
  line-height: 16px;
  color: #9191a6;
  font-weight: 700;
  font-family: sans-serif;
}

.right-blog-caption {
  color: #3ca6a6;
  font-weight: 400;
  font-size: 17px;
  font-family: sans-serif;
}

/* ------------------ EndBlog Page ----------------- */
/* ------------------ Home Page ----------------- */

.home-hero {
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.home-hero-inner-box {
  position: relative;
  z-index: 2;
  width: 100%;
  height: 650px;
  background-color: #012e40;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .home-hero-inner-box {
    height: 750px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .home-hero-inner-box {
    height: 600px;
  }
}

@media only screen and (max-width: 767px) {
  .home-hero-inner-box {
    height: 900px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .home-hero-inner-box {
    height: 1000px;
  }
}

.home-hero-inner-box .welcome-text {
  position: relative;
  z-index: 1;
}

@media only screen and (max-width: 767px) {
  .home-hero-inner-box .welcome-text {
    padding-top: 50px;
  }
}

.home-hero-inner-box .welcome-text h2 {
  font-size: 50px;
  letter-spacing: 1px;
  line-height: 55px;
  color: #ffffff;
  font-family: "Outfit";
  display: block;
}

.home-hero-inner-box .welcome-text p {
  font-size: 16px;
  opacity: 0.8;
  line-height: 18px;
  color: #f2e3d5;
  font-family: "Outfit";
}

.home-hero-inner-box .welcome-text button {
  width: 138px;
  height: 32px;
  border-radius: 20px;
  background-color: #3ca6a6;
  font-size: 15px;
  line-height: 18px;
  color: #ffffff;
  font-family: "Outfit";
  text-align: center;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .home-hero-inner-box .welcome-text h2 {
    font-size: 48px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .home-hero-inner-box .welcome-text h2 {
    font-size: 42px;
  }
}

@media only screen and (max-width: 767px) {
  .home-hero-inner-box .welcome-text h2 {
    font-size: 36px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .home-hero-inner-box .welcome-text h2 {
    font-size: 42px;
  }
}

.home-hero-inner-box .welcome-text h2 span {
  color: #ffffff;
}

.home-hero-inner-box .welcome-text h5 {
  font-size: 22px;
  font-weight: normal;
  color: #4d4d4d;
  margin-bottom: 60px;
  display: block;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .home-hero-inner-box .welcome-text h5 {
    font-size: 18px;
    margin-bottom: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .home-hero-inner-box .welcome-text h5 {
    font-size: 14px;
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .home-hero-inner-box .welcome-thumbnail {
    margin-top: -120px;
  }
}

.how-it-works {
  margin: 10px 120px;
  position: relative;
}

.how-it-works .side-curve-img {
  position: absolute;
  top: 25%;
  left: -35%;
}

.how-it-works .top-text p {
  line-height: 18px;
  color: #012e40;
  font-family: "Outfit";
  text-align: center;
}

.how-it-works .top-text h2 {
  font-size: 30px;
  line-height: 36px;
  color: #012e40;
  font-family: "Outfit";
  text-align: center;
  margin-bottom: 20px;
}

.how-it-works .row .col-md-4 {
  padding: 10px 60px;
}

.border-box {
  background-color: #000000;
  border: 3px solid #012e40;
  margin: 5px 55px;
}

.how-it-works .how-icons {
  justify-content: center;
  align-items: center;
  display: flex;
}

.how-it-works .how-icons .icon {
  width: 75px;
  height: 75px;
  border-radius: 38px;
  background-color: #3ca6a6;
  line-height: 65px;
}

.how-it-works .row .col-md-4 p {
  line-height: 18px;
  color: #012e40;
  font-family: "Outfit";
  text-align: center;
}

.carousel-wrapper {
  height: 550px;
  background-image: linear-gradient(249deg, #abe8e8 0%, #026773 100%);
  padding: 40px 100px;
}

.carousel-wrapper p {
  line-height: 17px;
  color: #ffffff;
  font-family: "Outfit";
  text-align: center;
}

.carousel-wrapper h5 {
  font-size: 30px;
  line-height: 5px;
  color: #ffffff;
  font-family: "Outfit";
  text-align: center;
}

.hc-carousel img {
  padding-top: 80px;
}

.bn-carousel img {
  padding-top: 20px;
}

.corousel-text {
  margin-top: 50px;
}

.blog-carousel-wrapper {
  height: 510px;
  padding: 30px;
  margin-top: 10px;
}

.blog-carousel-wrapper .row {
  margin: 10px 150px;
}

.blog-carousel-wrapper .row .col-md-4 {
  padding: 0 50px;
}

.blog-carousel-wrapper p {
  line-height: 20px;
  color: #012e40;
  font-family: "Outfit";
  text-align: center;
}

.blog-carousel-wrapper h5 {
  font-size: 30px;
  line-height: 25px;
  color: #012e40;
  font-family: "Outfit";
  text-align: center;
}

.blog-carousel-wrapper .bImg {
  width: 200px;
  border-radius: 65px;
  /* background-color: #3ca6a6; */
  object-fit: cover;
}

.blog-carousel-wrapper button img {
  width: 23px;
  height: 23px;
  padding: 4px;
  border-radius: 11px;
  background-color: #3ca6a6;
}

.carousel-wrapper button img {
  width: 23px;
  height: 23px;
  padding: 4px;
  border-radius: 11px;
  background-color: #fafafa;
}

.home-footer {
  height: 140px;
  background-color: #012e40;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 40px;
}

.home-footer p {
  color: white;
  opacity: 0.9;
  line-height: 22px;
  font-weight: normal;
}

/* ------------------ End Home Page ----------------- */
/* ------------------ Blog ----------------- */
.all-posts {
  margin: 10px 80px;
}

.all-posts .row .col-md-4 {
  /* margin: 10px 120px; */
  justify-content: center;
  align-items: center;
  display: flex;
  margin: 30px 0;
}

.all-posts ul {
  display: flex;
}

.all-posts ul li {
  list-style-type: none;
  margin: 20px 25px -10px 0;
}

.blog-comment .circle {
  width: 50px;
  /* Set the desired width */
  height: 50px;
  /* Set the desired height */
  border-radius: 50%;
  /* Make the container circular */
  overflow: hidden;
  /* Clip the image within the circular container */
}

.blog-comment .circle img {
  width: 100%;
  /* Make the image fill the container */
  height: 100%;
  /* Make the image fill the container */
  object-fit: cover;
  /* Maintain aspect ratio and cover the entire container */
}

.blog-comment .reply {
  font-size: 10px;
  letter-spacing: 0px;
  line-height: 16px;
  color: #3ca6a6;
  font-weight: 700;
  font-family: sans-serif;
  cursor: pointer;
}

.form-reply .form-control {
  border-radius: 25px;
  background-color: #f1f8fc;
  border: none;
}

.form-reply .form-control::placeholder {
  color: #9191a6;
  font-size: 14px;
  padding-left: 5px;
  line-height: 30px;
  font-family: sans-serif;
}

.form-reply button {
  width: 100px;
  height: 30px;
  border-radius: 15px;
  background-color: #3ca6a6;
  font-size: 10px;
  color: #ffffff;
  font-family: "Outfit";
  font-weight: normal;
}

.form-reply button:hover {
  border: 1px solid#3ca6a6;
  color: #3ca6a6;
}

.blog-right-section .form-control {
  border-radius: 25px;
  background-color: #f1f8fc;
  border: none;
  color: #9191a6;
  font-weight: 400;
  font-family: sans-serif;
}

.blog-right-section .form-control::placeholder {
  color: #9191a6;
  font-size: 14px;
  padding-left: 5px;
  line-height: 15px;
}

.blog-tags .active {
  background-image: linear-gradient(-60deg, #026773 0%, #93d8d8 100%);
  color: whitesmoke;
}

.blog-tags button:focus {
  background-image: linear-gradient(-60deg, #026773 0%, #93d8d8 100%);
  color: whitesmoke;
}

.blog-tags button:hover {
  background-image: linear-gradient(-60deg, #026773 0%, #93d8d8 100%);
  color: whitesmoke;
}


/* ------------------ End Blog ----------------- */

/* ------------- Media Queries ----------------*/

@media (max-width: 575px) {
  .portfolio-wrapper {
    width: 100%;
  }

  .top-performer-wrapper {
    width: 100%;
  }

  .all-posts {
    margin: 0;
  }

  .all-posts ul {
    display: flex;
    justify-content: center;
    align-items: center;

  }

  .all-posts .row {
    margin: -25px 0 0 0;
  }

  .all-posts ul li {
    /* margin: 7px; */
    font-size: 12px;
  }

  .blog-right-section {
    margin-top: 20px;
  }

  .how-it-works {
    margin: 10px;
  }

  .carousel-wrapper {
    height: 650px;
    background-image: linear-gradient(249deg, #abe8e8 0%, #026773 100%);
    padding: 40px 10px;
  }

  .carousel-wrapper h5 {
    font-size: 30px;
    line-height: 30px;
  }

  .home-footer {
    height: auto;
    background-color: #012e40;
    display: block;
    padding: 15px 0;
  }

  .home-footer .logo-footer,
  .footer-social {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 40px 0;
  }

  .welcome-thumbnail {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .welcome-thumbnail img {
    width: 250px;
    margin-top: -200px;
    opacity: 0.05;

  }

  .home-hero-inner-box {
    height: 400px;
  }

  .sidebarcollapse {
    display: none;
  }

  .account-topbar {
    height: 140px !important;
  }

  .account-container {
    margin-top: 10px !important;
  }

  .account-container .img-box {
    height: 120px !important;
  }

  .account-container .details-box {
    height: 50px !important;
    bottom: 0 !important;
  }

  .profile-img {
    width: 50px !important;
    height: 50px !important;
    border-radius: 50% !important;
    margin: -10px 10px 0 20px !important;
  }

  .profile-img h1 {
    font-size: 25px !important;
  }

  .account-topbar .details-box h2 {
    font-size: 13px !important;
    padding: 0 !important;
    margin-top: 14px !important;
  }

  .account-topbar .details-box .profile-info {
    font-size: 5px !important;
    margin-left: 0 !important;
  }

  .account-topbar .details-box .profile-info p {
    margin: 0 !important;
  }

  .account-topbar .details-box .profile-info .icon {
    margin: 0 !important;
  }

  .sm-svg {
    margin-top: -170px !important;
  }

  .profile-info {
    display: none !important;
  }

  .email-box-wrapper .form-control {
    width: 100% !important;
  }

  .heading {
    font-size: 25px !important;
    margin: 0 !important;
  }

  .top-bar {
    height: 85px !important;
  }

  .sort-by p {
    color: white !important;
    margin-right: 7px;
    font-size: var(--font-size-xxxxs);
  }

  .sort-by .form-select {
    font-size: var(--font-size-xxxxs);
    border-radius: 0;
    margin-top: 5px !important;
  }

  .sort-by p {
    margin: 7px 10px !important;
  }

  .sort-by {
    margin-right: 3px !important;
  }

  .top-bar ul {
    display: flex;
    list-style-type: none;
    padding: 1 !important;
    text-align: center;
  }

  .top-bar ul li a {
    margin: auto 5px !important;
    color: #ffffff;
    font-size: var(--font-size-xxs) !important;
  }

  #sidebar {
    display: none !important;
  }

  /* Pricing */
  .pricing-container {
    margin: 10px !important;
  }

  .pricing-container .col-md-4 {
    margin: 10px 0 !important;
  }

  .pricing-faq {
    height: 44px !important;
  }

  .heading-text {
    margin-top: 35px !important;
  }

  /* Portfolio */
  .box-container .box {
    margin: 10px 0 !important;
  }

  .portfolio-container {
    height: 180px !important;
  }

}

.add-portfolio {
  background: #026773;
}

.sign-in-bckgrnd {
  /* display: inline; */
  /* height: 100vh; */
  width: 100vw;
  background: linear-gradient(253.6deg, #93d8d8 10.14%, #026773 91.07%);
}

.sign-in-foregrnd {
  background: #026773;
  min-height: 1150px;
  max-width: 900px !important;

  /* display: block; */
  padding-top: 3%;
  padding-right: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  margin: auto;
}

.copyright-text {
  font-size: var(--font-size-xxxxs);
}

.add-portfolio-header {
  color: var(--blue-whale);
  font-size: 35px;
  font-weight: 700;
}

.sign-in-header {
  color: var(--blue-whale);
  font-size: 35px;
  font-weight: 700;
  min-height: 46px;
  margin-top: 30px;
  margin-left: 2px;
  min-width: 268px;
  letter-spacing: 1.75px;
  line-height: 21px;
  white-space: nowrap;
}

.sign-in-ask {
  color: var(--trout);
  font-size: var(--font-size-xxs);
  font-weight: 400;
  font-style: normal;

  min-height: 25px;
  margin-top: 4px;
  margin-left: 2px;
  min-width: 228px;
  opacity: 0.5;
  text-align: center;
  letter-spacing: 0;
  line-height: 13px;
  white-space: nowrap;
}

.error-msg {
  color: var(--red);
  font-size: var(--font-size-xxs);
  font-weight: 500;
  font-style: normal;
  text-align: center;
}

.sign-in-form {
  font-family: var(--font-family-outfit) !important;
  background-color: var(--white);
  min-height: 60%;
  max-width: 600px !important;

  display: block;
  padding-top: 3%;
  padding-bottom: 3%;
  margin-top: 50px !important;
  margin: auto;
}

/* .add-portfolio-card {
  width: 75%;
} */

.add-portfolio-form {
  font-family: var(--font-family-outfit) !important;
  background-color: var(--white);
  min-height: 60%;
  max-width: 600px !important;

  display: block;
  padding-bottom: 1%;
  margin-top: 30px !important;
  margin: auto;
}

.sign-in-label {
  font-family: var(--font-family-outfit) !important;
}

.diclaimer-container>div {
  display: inline-block;
  margin-right: 3px;
  margin-left: 3px;
}

.autocomplete-input {
  border: 1px solid #999;
  padding: 0.8rem;
  /* width: 100px; */
  max-width: 250px;
  font-family: "Outfit", Helvetica;
}

.no-suggestions {
  color: #999;
  padding: 0.5rem;
}

.virt-suggestions {
  font-size: var(--font-size-xxxxs);
  font-family: "Outfit", Helvetica;
  padding: 0.4rem;
}

.virt-suggestions:hover {
  background-color: #DBEDEB;
  color: #808080;
  cursor: pointer;
  padding: 0.5rem;
}

.suggestions {
  border: 1px solid #999;
  border-top-width: 0;
  list-style: none;
  margin-top: 0;
  max-height: 143px;
  overflow-y: auto;
  padding-left: 0;
  width: 100%;
  font-size: var(--font-size-xxxxs);
  max-width: 250px;
  /* width: calc(180px + 1rem); */
}

.suggestions li {
  padding: 0.5rem;
}

.suggestion-active,
.suggestions li:hover {
  background-color: #DBEDEB;
  color: #808080;
  cursor: pointer;
  font-weight: 700;
}

.suggestions li:not(:last-of-type) {
  border-bottom: 1px solid #999;
}

.accept-disclaimer-text {
  font-size: var(--font-size-xxxs) !important;
}

/* .portfolio-weight-input {
  max-width: 40% !important;
  border: 1px solid #999;
  padding: 0.8rem;
  font-family: "Outfit", Helvetica;
} */

.btn-circle.btn-xl {
  width: 70px;
  height: 70px;
  padding: 10px 16px;
  border-radius: 35px;
  font-size: 24px;
  line-height: 1.33;
  color: var(--mosque);
}

.btn-circle {
  width: 30px;
  height: 30px;
  padding: 6px 0px;
  border-radius: 20px;
  text-align: center;
  font-size: 12px;
  line-height: 1.42857;
  background-color: var(--white);
  color: var(--mosque);
  border: 2px solid #026773;
}

.portfolio-result-black {
  color: var(--black);
}

.portfolio-result-green {
  color: var(--green);
}

.portfolio-result-light-green {
  color: var(--green-light);
}

.portfolio-result-red {
  color: var(--red);
}

.portfolio-result-dark-red {
  color: var(--red-dark);
}

.hide {
  display: none;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@import url("https://fonts.googleapis.com/css?family=Outfit:400,600,300,500,700|Open+Sans:400,400italic,700,800|Source+Sans+Pro:400,700|Roboto:700|Nunito:600");

:root { 
  --alice-blue: #f1f8fc;
  --athens-gray: #f0f0f7;
  --black: #000000;
  --blue-whale: #012e40;
  --blue-whale-2: #012e4026;
  --blue-whale-3: #012e4033;
  --cararra: #ebebeb;
  --celeste: #cecece;
  --cloud: #c5c5c5;
  --concord: #7d7d7d;
  --dove-gray: #707070;
  --dove-gray-2: #70707033;
  --eerie-black: #1e1e1e;
  --green: #198754;
  --green-light: #25c97d;
  --gun-powder: #43425d;
  --keppel: #3ca6a6;
  --manatee: #9191a6;
  --mosque: #026773;
  --mosque-2: #02677331;
  --mountain-mist: #959595;
  --mystic: #e9e9f0;
  --ocean-blue-pearl: #4b2bb0;
  --red: #dc3545;
  --red-dark: #AF0C04;
  --santas-gray: #9fa4af;
  --storm-dust: #656565;
  --stratos: #02013d;
  --sun: #f8a90f;
  --trout: #4d4f5c;
  --trout-2: #4d565c;
  --waterloo-: #808495;
  --white: #ffffff;
 
  --font-size-l: 30px;
  --font-size-m: 24px;
  --font-size-s: 22px;
  --font-size-xl: 30px;
  --font-size-xs: 20px;
  --font-size-xxs: 18px;
  --font-size-xxxs: 16px;
  --font-size-xxxxs: 12px;
 
  --font-family-helveticaneue-regular: "HelveticaNeue-Regular", Helvetica;
  --font-family-nunito: "Nunito", Helvetica;
  --font-family-open_sans: "Open Sans", Helvetica;
  --font-family-outfit: "Outfit", Helvetica;
  --font-family-roboto: "Roboto", Helvetica;
  --font-family-source_sans_pro: "Source Sans Pro", Helvetica;
}

@font-face {
  font-family: "HelveticaNeue-Regular";
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/HelveticaNeue.ttf") format("truetype");
}
